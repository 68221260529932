<template>
  <button v-if="isButton" type="button" class="bg-primary text-white text-[15px] text-center font-semibold py-[1.18rem] px-9 rounded-full">
    <slot></slot>
  </button>
  <NuxtLink
    v-else
    :to="link"
    :target="openNewTab ? '_blank' : '_self'"
    class="text-[15px] text-center font-semibold py-[1.18rem] px-9 rounded-full"
    :class="{
      'bg-white hover:bg-primary text-primary hover:text-white border-2 border-primary': !isPrimary,
      'bg-primary text-white': isPrimary,
    }"
  >
    <slot></slot>
  </NuxtLink>
</template>
<script setup lang="ts">
const openNewTab = ref(false)
const props = defineProps({
  isPrimary: {
    type: Boolean,
    default: true,
  },
  isButton: {
    type: Boolean,
    default: false,
  },
  link: {
    type: String,
    default: '',
  },
})

if (props.link && props.link?.startsWith('http')) openNewTab.value = true
</script>
