import type { ArticleContent, ParishConfig, Seo } from '~/utils/types'

export const useGlobalSetting = () => useState('global-setting', () => ({}))
export const useHomepage = () => useState('homepage', (): any => ({}))
export const useHomepageArticles = () => useState('homepage-articles', (): any[] => [])
export const useMainNavigation = () => useState('main-navigation', (): any[] => [])
export const useFooterNavigation = () => useState('footer-navigation', (): any[] => [])
export const useOffice = () => useState('office', () => ({}))
export const usePodcasts = () => useState('podcasts', (): any => ({}))
export const useCurrentPodcastPlaying = () => useState('current-podcast-playing', () => '')
export const useOpenNavigation = () => useState('open-navigation', () => false)
export const useLiturgicalCalendar = () => useState('liturgical-calendar', (): object => ({}))
export const usePage = () => useState('page', (): any => ({}))
export const useArticles = () => useState<ArticleContent[]>('articles', () => [])
export const useArticleCategories = () => useState('article-categories', (): any => [])
export const useArticle = () => useState('article', (): any => ({}))
export const useParishNotices = () => useState('parish-notices', (): any => ({}))
export const useParishConfig = () => useState<ParishConfig>('parish-config', () => ({}) as ParishConfig)
export const useGlobalSeo = () => useState<Seo | null>('global-seo', (): any => null)
export const useLastFetchAt = () => useState<any>('last-fetch-at', (): any => ({}))
