export default () => {
  const {
    public: { strapiAssetsUrl, cdnUrl },
  } = useRuntimeConfig()

  return (url: string | undefined) => {
    if (!url) return ''

    if (cdnUrl && url.startsWith('/uploads')) return cdnUrl + url.replace('/uploads/', '/')
    if (url.includes(strapiAssetsUrl) || url.startsWith('http')) return url

    return strapiAssetsUrl + url
  }
}
