import dayjs from 'dayjs'

import { ArticleCategory, type ParishConfig } from '~/utils/types'

const findEpisode = () => {
  const route = useRoute()
  const podcasts = usePodcasts()
  const slug = ref<string>('')

  if (route.params?.slug) slug.value = [...route.params.slug].join('/')

  const { basic: _basic, ...rest } = podcasts.value

  for (const podcast in rest) {
    for (const episode of rest[podcast].items) {
      if (episode.url === slug.value) {
        return episode
      }
    }
  }

  return null
}

const initParishConfig = async () => {
  const runtimeConfig = useRuntimeConfig()
  const parishConfig = useParishConfig()
  const parishLocale = (runtimeConfig.public.parishLocale || '').toLowerCase()
  const configJson = await import(`../config/${parishLocale}/config.json`)

  parishConfig.value = configJson.default as ParishConfig
}

const getGqlPage = async (page: any) =>
  new Promise((resolve, reject) => {
    const route = useRoute()
    const { ToArticle } = useMappers()

    if (!page.data?.length) {
      if (route.path.includes(ArticleCategory.blog)) {
        const episode = findEpisode()
        if (episode) return resolve(ToArticle(episode, true))
      }

      reject('PNF')
    }

    resolve(ToArticle(page.data?.[0]?.attributes))
  })

const isContentOld = (timestamp: string): boolean => {
  if (!timestamp) return true
  return dayjs().isAfter(dayjs(timestamp).add(30, 'minute'))
}

export default () => {
  return {
    getGqlPage,
    initParishConfig,
    isContentOld,
  }
}
