import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: config } = useRuntimeConfig()
  const parishConfig = useParishConfig()

  if (!config.sentryDsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.sentryDsn,
    // @ts-ignore
    release: `${__APP_NAME__}@${__APP_VERSION__}`,
    environment: config.environment || 'development',
    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.httpClientIntegration(),
      Sentry.reportingObserverIntegration(),
    ],
    tracePropagationTargets: [...(parishConfig.value.sentry ? parishConfig.value.sentry.tracePropagationTargets : []), /^\//],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
    tracesSampleRate: +config.sentryTracesSampleRate || 1.0,
    replaysSessionSampleRate: +config.sentryReplaysSessionRate || 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  })

  nuxtApp.hook('app:error', (err) => {
    if (err.statusCode === 404) return
    Sentry.captureException(err)
  })
})
