<template>
  <NuxtPwaManifest />
  <NuxtLayout>
    <NuxtLoadingIndicator :color="parishConfig.colors.primary" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { registerSW } from 'virtual:pwa-register';

const parishConfig = useParishConfig()

const labelStyle = `
        background-color: ${parishConfig.value.colors.primary};
        color: white;
        padding: 2px 6px;
        border-radius: 5px 0 0 5px;
        font-weight: bold;
    `
const versionStyle = `
        background-color: ${parishConfig.value.colors.secondary.dark};
        color: #2C2926;
        padding: 2px 6px;
        font-weight: bold;
    `
const datetimeStyle = `
        background-color: #2C2926;
        color: white;
        padding: 2px 6px;
        border-radius: 0 5px 5px 0;
        font-weight: bold;
        margin-left: auto;
    `

onMounted(() => {
  console.log(`%cCatholic Unified Platform%c${__APP_VERSION__}%c${dayjs().format('DD.MM.YYYY HH:mm:ss')}`, labelStyle, versionStyle, datetimeStyle)
  registerSW({ immediate: true })
})
</script>
