import type { Dayjs } from 'dayjs'

export const isOffline = () => {
  return process.client && !window?.navigator?.onLine
}

export const offlineRoutes = ['/farske-oznamy']

export const formatToGraphqlDate = (date: Dayjs) => {
  return date.format('YYYY-MM-DD')
}

export const formatToSlovakDate = (date: Dayjs) => {
  return date.format('DD. MM. YYYY')
}

export const chunkArray = (array: any[], size: number) => {
  const chunkedArray = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }
  return chunkedArray
}
