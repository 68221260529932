export default defineNuxtRouteMiddleware((to, from) => {
  // Redirect every URL with trailing slash to non-trailing slash
  if (to.path !== '/' && to.path.endsWith('/')) {
    const { path, query, hash } = to
    const nextPath = path.replace(/\/+$/, '') || '/'
    const nextRoute = { path: nextPath, query, hash }

    return navigateTo(nextRoute, { redirectCode: 301 })
  }

  // Redirect every URL to lowercase
  if (to.path !== to.path.toLowerCase()) {
    const { path, query, hash } = to
    const lowerCasePath = path.toLowerCase()
    const nextRoute = { path: lowerCasePath, query, hash }

    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
