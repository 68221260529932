<template>
  <div class="min-h-screen pb-8 flex flex-col justify-center items-center space-y-6">
    <Heading :size="1" :text="String(error?.statusCode)" />
    <div class="container__content">
      <p class="text-base text-grey-light lg:text-lg">{{ error?.message }}</p>
    </div>
    <UtilityButton is-button @click="handleError">Späť na domovskú stránku</UtilityButton>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const { initParishConfig } = useUtils()
await initParishConfig()

const parishConfig = useParishConfig()

const props = defineProps({
  error: Object as () => NuxtError,
})

const shrinkErrMessage = computed(() => props.error?.message?.substring(0, 40))

provide('isBigHeading', true)
useSeoMeta({
  title: `${props.error?.statusCode}, ${shrinkErrMessage.value} | ${parishConfig.value.name}`,
})

const handleError = () => clearError({ redirect: '/' })
</script>

<style scoped lang="scss">
p {
  overflow-wrap: break-word;
}
</style>
