export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server || !to.name) return

  const { isContentOld } = useUtils()
  const { SetTimestamp } = useMappers()
  const lastFetchAt = useLastFetchAt()

  if (!lastFetchAt.value[to.name]) {
    lastFetchAt.value[to.name] = SetTimestamp()
    return
  }

  if (!to.query?.refresh && isContentOld(lastFetchAt.value[to.name])) {
    lastFetchAt.value[to.name] = SetTimestamp()

    return navigateTo({
      path: to.path,
      query: {
        ...to.query,
        refresh: 'true',
      },
      hash: to.hash,
    })
  }
})
