<template>
  <div>
    <h1
      v-if="size === 1"
      v-html="text"
      class="text-[1.75rem] text-grey font-serif font-bold leading-normal"
      :class="{ headingClass, 'lg:text-5xl': !isBigHeading, 'lg:text-6xl': isBigHeading }"
    ></h1>
    <h2 v-if="size === 2" v-html="text" class="text-[1.75rem] lg:text-4xl text-grey font-serif font-bold leading-normal" :class="headingClass"></h2>
    <h3 v-if="size === 3" v-html="text" class="text-[1.35rem] lg:text-2xl text-grey font-serif font-bold" :class="headingClass"></h3>
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: Number,
    default: 2,
  },
  text: String,
  headingClass: String,
})

const isBigHeading = inject('isBigHeading', false)
</script>
