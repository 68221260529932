export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    public: { host },
  } = useRuntimeConfig()
  const parishConfig = useParishConfig()
  const { initParishConfig } = useUtils()

  if (!Object.keys(parishConfig.value).length) {
    await initParishConfig()
  }

  useHead({
    link: [
      {
        rel: 'canonical',
        href: host + (to.path === '/' ? '' : to.path),
      },
    ],
  })

  return
})
